<template>
    <div id="checkImg">
        <div class="bigImg" v-if="showbigImg">
            <div class="mode" @click="close()"></div>
            <div class="imgbox">
                <img :src="bigImgSrc" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showbigImg: Boolean,
        bigImgSrc: String
    },
    data(){
        return{
            showImg:this.showbigImg
        }
    },
    methods:{
        close(){
            this.showImg=false
            this.$emit('update:showbigImg', this.showImg); 
        }
    }
};
</script>

<style lang="scss" scoped>
.bigImg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    cursor: pointer;
    .mode {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.5;
        background: #000;
    }
    .imgbox {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            transform: scale(1) rotate(0deg);
            margin-left: 0px;
            margin-top: 0px;
            max-height: 100%;
            max-width: 100%;
        }
    }
}
</style>