<template>
    <div class="historyOrder">
        <div class="title">历史订单</div>
        <!-- 顶部操作栏 -->
        <div class="headBox">
            <div class="right">
                <el-input placeholder="输入ID" clearable v-model="id" size="mini" @input="getData">
                    <template slot="prepend">ID:</template>
                </el-input>
                <el-input placeholder="输入姓名" clearable v-model="customerName" size="mini" @input="getData">
                    <template slot="prepend">姓名:</template>
                </el-input>
                <div class="select">
                    <div class="selectTit">签约状态:</div>
                    <el-select v-model="signingStatus" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in signingStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="select">
                    <div class="selectTit">签约类型:</div>
                    <el-select v-model="signingType" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in signingTypeOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
                <div class="select">
                    <div class="selectTit">订单类型:</div>
                    <el-select v-model="orderStatus" clearable placeholder="请选择" size="mini" @change="getData">
                        <el-option
                            v-for="item in orderStatusOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <!-- 表格栏 -->
        <table-list
            :ButtonWidth="140"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            :selection="$route.query.recovery == 0"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                <span v-if="scope.colr.prop == 'signingType'">
                    {{ scope.row[scope.colr.prop] == 0 ? "单笔" : "分期" }}
                </span>
                <span v-else-if="scope.colr.prop == 'signingStatus'">
                    <span v-if="scope.row[scope.colr.prop] == 1" style="color: #67c23a">
                        {{scope.row["signingStatusText"]}}
                    </span>
                    <span v-else-if="scope.row[scope.colr.prop] == 2" style="color: #e6a23c">
                        {{ scope.row["signingStatusText"] }}
                    </span>
                    <span v-else-if="scope.row[scope.colr.prop] == 3" style="color: #e44c4c">
                        {{ scope.row["signingStatusText"] }}
                    </span>
                    <span v-else>{{ scope.row["signingStatusText"] }}</span>
                </span>
                <span v-else-if="scope.colr.prop == 'contractOrderId'">
                    <span style="color: #409eff; cursor: pointer" @click="check(scope.row)">{{
                        scope.row[scope.colr.prop]
                    }}</span>
                </span>
            </template>
            <template #Button="scope">
                <el-button size="mini" type="text" @click="check(scope.row)">查看</el-button>
                <el-button size="mini" type="text" @click="openListNews(scope.row)">扣款详情</el-button>
                <!-- <el-button size="small" type="text" @click="del(scope.row)" style="color: #e44c4c">删除</el-button> -->
            </template>
        </table-list>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import historyOrderApi from "../../api/historyOrderApi";
import checkImg from "../../components/comm/checkImg.vue";
export default {
    name: "historyOrder",
    components: { TableList, checkImg },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "订单号", prop: "id", width: 230 },
                { label: "客户姓名", prop: "customerName", width: 100 },
                { label: "金额(元)", prop: "signingAmount", width: 100 },
                { label: "签约状态", prop: "signingStatus", width: 100 },
                { label: "签约类型", prop: "signingType", width: 100 },
                { label: "订单状态", prop: "orderStatusText", width: 120 },
                { label: "签约日期", prop: "createTime", width: 180 },
                { label: "订单完成日期", prop: "signingCompleteTime", width: 180 },
                { label: "解约日期", prop: "settlementCompleteTime", width: 180 },
                { label: "结算类型", prop: "settlementType", width: 100 },
                { label: "", prop: "", width: "" }
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,

            checkDialogVisible: false,
            form: {},
            checkForm: {},
            customerName: "",

            showbigImg: false,
            bigImgSrc: "",

            customerName: "",
            id: "",
            signingStatusOptions: [
                {
                    label: "未签约",
                    value: 0
                },
                {
                    label: "已签约",
                    value: 1
                },
                {
                    label: "签约中",
                    value: 2
                },
                {
                    label: "已解约",
                    value: 3
                },
                {
                    label: "手动解约",
                    value: 4
                },
                {
                    label: "自动解约",
                    value: 5
                }
            ],
            signingStatus: "",
            signingTypeOptions: [
                {
                    label: "单笔",
                    value: 0
                },
                {
                    label: "分期",
                    value: 1
                }
            ],
            signingType: "",
            orderStatus:'',
            orderStatusOptions:[
                {
                    label: "按约还款",
                    value: 0
                },
                {
                    label: "线上提前还款",
                    value: 1
                },
                {
                    label: "线下提前还款",
                    value: 2
                },
                {
                    label: "关闭订单",
                    value: 3
                }
            ]
        };
    },
    mounted() {
        document.title = "代扣系统-历史订单";
        this.getData();
    },
    methods: {
        getData() {
            this.$ajax
                .post(
                    "//safepayment.com.cn:18080/historyOrders/page?pageSize=" +
                        this.pagesize +
                        "&pageNumber=" +
                        this.pageIndex,
                    {
                        customerName: this.customerName,
                        id: this.id,
                        signingStatus: this.signingStatus,
                        signingType: this.signingType,
                        orderStatus:this.orderStatus
                    }
                )
                .then(res => {
                    if (res.status == 200) {
                        this.tableData = res.data.records;
                        this.total = res.data.total;
                    }
                });
        },
        checkhandleClose() {
            this.checkDialogVisible = false;
        },
        handStatus(status) {
            switch (status) {
                case 0:
                    return "未签约";
                case 1:
                    return "已签约";
                case 2:
                    return "签约中";
                case 3:
                    return "已解约";
            }
        },
        check(row) {
            this.$router.push({ name: "/historyOrder/orderDetails", query: { id: row.id } });
        },
        openListNews(row) {
            this.$router.push({ name: "messagePage", query: { id: row.id } });
        },
        del(row) {
            this.$confirm("是否确认删除订单号为 " + row.id + " 的订单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.$ajax.del("//safepayment.com.cn:18080/historyOrders/delete/" + row.id).then(res => {
                        if ((res.status = 200)) {
                            this.$message({
                                type: "success",
                                message: "删除成功"
                            });
                            this.getData();
                        } else {
                            this.$message({
                                type: "warning",
                                message: "删除失败"
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
            this.getData();
        },
        handleSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pagesize = pageSize;
            this.getData();
        },
        handleBigImageUpdate(newValue) {
            this.showbigImg = newValue;
        }
    }
};
</script>
<style lang="scss" scoped>
.historyOrder {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    min-height: 85vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: right;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }

    .elTag {
        margin-bottom: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 320px;
        .elTagSpan {
            display: flex;
            align-items: center;
            img {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }
        }
    }

    /deep/.el-table .cell {
        line-height: normal !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
    /deep/ .el-button--text {
        height: 16px;
        padding: 0;
    }

    .infoBox {
        .header_tit {
            height: 40px;
            line-height: 44px;
            background: rgb(241, 245, 249);
            font-weight: 700;
            font-size: 16px;
            padding-left: 10px;
            margin-bottom: 20px;
        }
        .infoItem {
            border-bottom: 1px solid #eee;
            padding: 0 10px 4px 10px;
            margin: 0 10px 20px;
            display: flex;
            justify-content: space-between;
            &:last-child {
                border: 0;
            }
            .tit {
                font-weight: 700;
            }
            .zjz {
                margin-left: 50px;
                margin-top: 5px;
                cursor: pointer;
                display: flex;
                gap: 10px;
                .img {
                    width: 120px;
                    height: 120px;
                    border: 1px dashed #d9d9d9;
                    margin-top: 15px;
                    &:first-child {
                        margin-bottom: 15px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .zjzInfo {
            display: block;
            margin: 0 10px 15px;
        }
    }
}
</style>
